import React, { Component } from 'react';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { user } from 'actions';
/**
 *  @typedef {Object} ConfirmChangesModalProps
 *  @prop {(result:Record<string, any>) => void} accept
 *  @prop {() => void } close
 *  @prop {() => void} closeModal
 *  @prop {() => void} fetchUserHandler
 *  @prop {Object} user
 *  @prop {string[]} waitlistRemovals
 *
 */

/**
 *  @typedef {Object} ConfirmChangesModalState
 *  @prop {Record<string, any>} fieldsForSubmit
 *  @prop {boolean} initialConfirm
 */

/**
 *  @extends {Component<ConfirmChangesModalProps, ConfirmChangesModalState>}
 */
class ConfirmChangesModal extends Component {
  state = {
    fieldsForSubmit: {},
    initialConfirm: false,
  };

  componentDidMount() {
    user.fieldsForSubmit({ formState: this.props.user }).then(fields => {
      const confirmedFields = { confirmed_changes: true, ...fields };
      this.setState({
        fieldsForSubmit: confirmedFields,
      });
    });
  }

  closeModal = () => {
    this.props.fetchUserHandler();
    this.props.close();
  };

  handleAccept = () => {
    this.closeModal();
    this.props.accept(this.state.fieldsForSubmit);
  };

  handleInitialAccept = () => {
    this.setState({ initialConfirm: true });
  };

  render() {
    const warning = msg => (
      <div className="alert alert-warning text-center">
        <FontAwesomeIcon icon="exclamation-triangle" /> {msg}
      </div>
    );

    return (
      <Modal isOpen>
        <h2>{warning('Waitlist eligibility changed')}</h2>
        {this.state.initialConfirm ? (
          <h3 className="text-center my-5">Are you sure?</h3>
        ) : (
          <div className="text-center">
            <h5>
              You have made some changes to your profile that have made you
              ineligible for the following waitlist(s):
            </h5>
            <p>{this.props.waitlistRemovals.join(', ')}</p>
            <h5>
              Saving these changes will result in your removal from these wait
              lists.
            </h5>
            <p>
              Please confirm that you would like to save these changes and
              remove yourself from the waitlists listed above.
            </p>
          </div>
        )}
        <div className="d-flex w-100 justify-content-between">
          <button
            className="btn btn-outline-secondary"
            onClick={this.closeModal}
          >
            {this.state.initialConfirm
              ? 'No, undo my changes'
              : 'Cancel and Undo Changes'}
          </button>
          <button
            className={
              'btn btn-green' + (this.state.initialConfirm ? ' d-none' : '')
            }
            onClick={this.handleInitialAccept}
          >
            Confirm and Save Changes
          </button>
          <button
            className={
              'btn btn-green' + (!this.state.initialConfirm ? ' d-none' : '')
            }
            onClick={this.handleAccept}
          >
            Yes, save my profile
          </button>
        </div>
      </Modal>
    );
  }
}

ConfirmChangesModal.propTypes = {
  accept: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ConfirmChangesModal;
