import { handleJSONResponse, apiURL, getToken } from 'utils/api';
import { getAddressFields, nestApplicantInfoFields } from 'utils/fields';
import { normalizeAddresses } from 'utils/usps';

const formatUpdateUserBody = state => {
  const { include_alt_contact, alt_contact_method } = state;
  const addresses = getAddressFields(state);

  return normalizeAddresses({
    addresses,
    include_alt_contact,
    alt_contact_method: alt_contact_method.value,
  }).then(addresses => {
    const values = nestApplicantInfoFields(
      Object.assign({}, state, ...addresses),
      'applicant_info_attributes',
    );

    values.household_members_attributes = values.household_members;
    delete values.household_members;

    values.locations = state.locations
      ? state.locations.map(location =>
          location.type === 'google_place'
            ? location.id
            : location.attributes.google_place_id,
        )
      : [];

    return values;
  });
};

const fetchUser = id => {
  const JWT = getToken();

  return fetch(apiURL(`/applicants/${id}`), {
    method: 'GET',
    headers: {
      ...JWT.token,
    },
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e);
      }

      throw msg.join(', ');
    });
};

const fieldsForSubmit = ({ formState }) => formatUpdateUserBody(formState);

const saveUser = (userId, values) => {
  const JWT = getToken();

  return fetch(apiURL(`/applicants/${userId}`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...JWT.token,
    },
    body: JSON.stringify({
      applicant: values,
    }),
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e);
      }

      throw msg.join(', ');
    });
};

const confirmWaitlistEligibility = (userId, values) => {
  const JWT = getToken();
  return fetch(apiURL(`/applicants/${userId}/confirm_eligibility`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...JWT.token,
    },
    body: JSON.stringify({
      applicant: values,
    }),
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .catch(err => {
      console.log(err);
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e);
      }

      throw msg.join(', ');
    });
};

const user = {
  fetchUser,
  fieldsForSubmit,
  saveUser,
  confirmWaitlistEligibility,
};

export default user;
